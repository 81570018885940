import React from 'react'
import Image from '~/components/elements/Image';
import { WpPage_FlexibleContent_FlexibleContent_FullWidthImage } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';

export default function FullWidthImage({
  section,
  image,
}: WpPage_FlexibleContent_FlexibleContent_FullWidthImage) {

  if(!image) return null

  return (
    <Section {...section}>
      <div className="container">
        <Image image={image} />
      </div>
    </Section>
  )
}
