import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_Values } from "~/_generated/types";
import Image from '~/components/elements/Image';
import { StaticImage } from "gatsby-plugin-image";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'


export default function Values({
  contentgroup,
  image,
  section
}: WpPage_Page_FlexibleContent_Values) {

  return (
    <Section {...section}>
      <div className="container">
        <div className="space-y-8 text-center">
          <div className="space-y-8 text-center">
            <FadeInWhenVisible>
              <h3 className="text-h3 max-w-[779px] leading-none mx-auto" dangerouslySetInnerHTML={{ __html: contentgroup.heading }} />
            </FadeInWhenVisible>
            
            <FadeInWhenVisible className="p-xlarge max-w-[693px] mx-auto" dangerouslySetInnerHTML={{ __html: contentgroup.content }} />
          </div>
          <div className="flex flex-wrap justify-center space-x-[37px]  max-w-[808px] mx-auto">
            {contentgroup.values?.map((value, i) => (
              <FadeInWhenVisible className="space-x-2.5 flex items-center mt-7" key={`values${i}`}>
                <StaticImage src={'../../assets/images/tick.png'} className="block w-[36px]" alt="" />
                <div className={`md:text-[20px] font-medium text-navy-dark pt-2`}>
                  {value.value}
                </div>
              </FadeInWhenVisible>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
}
