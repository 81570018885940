import React from 'react'
import Image from '~/components/elements/Image';
import { WpPage_FlexibleContent_FlexibleContent_ServicesCta } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import { Icon, IconType } from "~/components/elements/Icon";
import { StaticImage } from 'gatsby-plugin-image';
import Tick from '~/components/elements/Tick';

export default function ServicesCta({
  section,
  heading,
  features,
  image,
  link,
}: WpPage_FlexibleContent_FlexibleContent_ServicesCta) {
  return (
    <div className="relative">
      <Section {...section}>
        <div className="container">
          <div className="relative bg-navy text-white space-y-8 lg:space-y-0 lg:grid grid-cols-12 items-center px-8 lg:px-16 py-16 lg:py-32 lg:pb-40">
            <div className={`space-y-10 col-span-5 md:pl-10`}>
              <h3 className={`text-h3`} dangerouslySetInnerHTML={{ __html: heading }} />
              <div className="space-y-4">
                {features.map((feature, i) => (
                  <div className="flex items-start space-x-4" key={`servicesCtaFeature${i}`}>
                    <Tick />
                    <div className="pt-0.5">
                      {feature.content}
                    </div>
                  </div>
                ))}
              </div>
              <Button link={link.link} type={link.type} />
            </div>
            <div className="col-span-6 col-start-7">
              <Image image={image} />
            </div>
            <StaticImage src={'../../assets/images/services-cta-bg.png'} className="!absolute left-0 bottom-0 right-0" alt="" />
          </div>
        </div>
      </Section>
    </div>
  )
}
