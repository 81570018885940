import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Testimonial } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button";
import { Icon, IconType } from "~/components/elements/Icon";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Testimonial({
  section,
  company,
  logo,
  quote,
  cite,
  layout,
}: WpPage_FlexibleContent_FlexibleContent_Testimonial) {
  return (
    <Section {...section}>
      <div className="container">
        <div className="space-y-16">
          <div className="space-y-10">
            {(layout === 'services' && company) && (
              <FadeInWhenVisible className="space-x-3 flex items-center">
                <div className="text-lime fw-icon max-w-[17px]">
                  <Icon type={IconType.Pin} />
                </div>
                <div className="font-bold text-[17px] uppercase leading-none pt-1">
                  {company}
                </div>
              </FadeInWhenVisible>
            )}
            <FadeInWhenVisible className="w-[140px] mx-auto">
              <Image image={logo} />
            </FadeInWhenVisible>
            <FadeInWhenVisible className={` ${layout === 'services' ? '' : 'max-w-[860px] mx-auto text-center'}`}>
              <h3 className={`  !font-normal ${layout === 'services' ? 'text-span-lime text-h2 text-h2--alt' : 'text-span-blue text-h3 !leading-[1.2]' }`} dangerouslySetInnerHTML={{ __html: quote }} />
            </FadeInWhenVisible>
          </div>
          <FadeInWhenVisible className="">
            <Cite {...cite} layout={layout} />
          </FadeInWhenVisible>
        </div>
      </div>
    </Section>
  )
}

export const Cite = ({ image, namerole, link, layout }) => {
  return (
    <div className={`${layout === 'services' ? 'flex items-center space-x-14' : 'space-y-6'}`}>
      
      {image && (
        <div>
          <div className="mx-auto w-[136px] h-[136px]  relative">
            <StaticImage src={'../../assets/images/testimonial-author-bg.png'} className="block w-full h-full" alt="" />
            <div className="w-[120px] h-[120px] absolute -bottom-2 -right-3 rounded-full border-4 border-white overflow-hidden">
              <Image image={image} objectPosition="center" objectFit="cover" />
            </div>
          </div>
        </div>
      )}
      {(namerole || link) && (
        <div className={`flex flex-col space-y-3.5  ${layout === 'services' ? 'items-start' : 'items-center'}`}>
          <div className={` p-xlarge font-normal ${layout === 'services' ? '' : 'text-center'}`}>{namerole}</div>
          {link && (
            <Button link={link.link} type={link.type} />
          )}
        </div>
      )}
    </div>
  )
}
