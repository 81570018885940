import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Intro } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Intro({
  section,
  heading,
  content,
  layout,
  logos,
  logosPerRow
}: WpPage_FlexibleContent_FlexibleContent_Intro) {
  return (
    <Section {...section}>
      <div className="container">
        <div className={`text-center space-y-4`}>
          <FadeInWhenVisible transition={{delay: '0'}}>
            <h3 className={`text-h3 max-w-[720px] mx-auto intro-heading`} dangerouslySetInnerHTML={{ __html: heading }} />
          </FadeInWhenVisible>
          <FadeInWhenVisible transition={{delay: '.2s'}}>
            <div className={`p-xlarge max-w-[720px] mx-auto intro-content ${layout === '2' ? 'max-w-[672px] mx-auto' : ''}`} dangerouslySetInnerHTML={{ __html: content }} />
          </FadeInWhenVisible>
        </div>
        {(layout === 'logos' && logos.length > 0) && (
          <div className={`mt-16 grid grid-cols-2  ${logosPerRow === '5' ? 'md:grid-cols-5' : 'md:grid-cols-4'} gap-x-12 gap-y-16`}>
            {logos.map((logo, i) => (
              <FadeInWhenVisible transition={{delay: (i * .1)}} className="flex items-center justify-center" key={`intrologo${i}`}>
                <div className={`${logosPerRow === '4' ? 'h-20 w-36' : 'h-16 w-32'}`}>
                  <Image image={logo} objectFit="contain" className="w-full h-full" />
                </div>
              </FadeInWhenVisible>
            ))}
          </div>
        )}
      </div>
    </Section>
  )
}
