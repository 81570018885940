import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import Image from '~/components/elements/Image';
import { WpPage_FlexibleContent_FlexibleContent_Cta } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import { ButtonColor } from '~/components/elements/buttons/Button';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'


export default function Cta({
  section,
  heading,
  content,
  image,
  link,
  layout
}: WpPage_FlexibleContent_FlexibleContent_Cta) {
  return (
    <FadeInWhenVisible className={`relative ${layout === '2' && 'container cta-2'}`}>
      <Section {...section}>
        <div className="container space-y-12 md:space-y-28">
          {image && (
            <div className="max-w-[554px] mx-auto cta-image">
              <Image image={image} />
            </div>
          )}
          <div className={`max-w-[730px] mx-auto text-center space-y-8 md:space-y-12 cta-content-container`}>
            <div className="space-y-4 md:space-y-6">
              <h3 className={`text-h3 cta-heading`} dangerouslySetInnerHTML={{__html: heading}} />
              <div className={`${layout === '2' ? 'p-xlarge' : 'p-xxlarge'} cta-content`} dangerouslySetInnerHTML={{__html: content}} />
            </div>
            <Button link={link.link} type={link.type} color={ButtonColor.Black}/>
          </div>
        </div>
      </Section>
    </FadeInWhenVisible>
  )
}
