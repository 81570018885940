import React from 'react';
import { useField } from 'formik';

export default function MultiOptionSelect(props) {
  const { label, options, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const labelClassName = props.labelClassName;
  const optionContainerClassName = props.optionContainerClassName;
  const optionClassName = props.optionClassName;

  return (
    <label className="hidden md:block">
      <div
        className={`hidden md:flex flex-row gap-2.5 md:gap-0 md:space-y-0 md:space-x-2.5 justify-between pb-8 ${optionContainerClassName}`}
      >
        {options.map((option) => (
          <div
            key={option.value}
            onClick={() => helpers.setValue(option.value)}
            // onClick={() => tabHandler(option.value)}
            className={` select-none rounded-[68px] transition-colors duration-300 ease-in-out hover:bg-blue hover:text-white hover:border-blue text-center border border-navy flex-1 cursor-pointer appearance-none pb-2 pt-3 leading-none relative text-[17px] font-medium text-navy ${optionClassName} ${
              meta.value === option.value
                ? ' bg-blue !text-white border-blue '
                : isInvalid
                ? 'border-[#ff0000]'
                : 'border-black'
            }`}
            dangerouslySetInnerHTML={{ __html: option.label }}
          />
        ))}
      </div>

      <div className="visually-hidden-field">
        <input type="text" name="numberEmployees" value={meta.value} />
      </div>
    </label>
  );
}
