import { useField } from 'formik';
import React from 'react';
import { ErrorMessage } from './InputField';

export default function TextareaField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`flex flex-col text-black items-start flex-auto relative  ${props.className || ''}`}>
      <label className={`screenreader-text`}>{label}</label>
      <textarea
        rows={6}
        placeholder={label}
        className={`border rounded-sm border-navy-dark border-opacity-20 text-black bg-transparent w-full px-4 py-4 text-[17px] outline-none focus:border-opacity-100 transition-colors ${
          isInvalid && 'border border-red-500'
        }`}
        {...field}
        {...other}
      />
      {isInvalid && <ErrorMessage className=" top-[98%]" error={meta.error} />}
    </div>
  );
}
