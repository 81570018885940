import { Link } from "gatsby";
import React, { useState } from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Faqs } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon, IconType } from "~/components/elements/Icon";
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Faqs({
  section,
  heading,
  faqs,
}: WpPage_FlexibleContent_FlexibleContent_Faqs) {
  return (
    <Section {...section}>
      <div className="relative">
        <div className="container">
          <div className={`space-y-16`}>
            <FadeInWhenVisible>
              <h3 className={`text-h3 text-center`} dangerouslySetInnerHTML={{ __html: heading }} />
            </FadeInWhenVisible>
            <div className="max-w-[590px] mx-auto space-y-12">
              <Items faqs={faqs} />
              <FadeInWhenVisible className="space-y-2.5">
                <div className="font-medium p-xlarge">Got any other questions?</div>
                <div className="flex items-center space-x-2.5">
                  <Button link={{ url: '/contact', title: "Contact us" }} type={ButtonType.Text} />
                </div>
              </FadeInWhenVisible>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}


export const Items = ({ faqs }) => {

  const [expanded, setExpanded] = useState(null);

  return (
    <div className="border-b border-b-black border-opacity-10">
      {faqs.map((faq, i) => (
        <FadeInWhenVisible className="border-t border-t-black border-opacity-10 py-4" key={`faq${i}`}>
          <header className="group cursor-pointer relative" onClick={() => setExpanded(expanded === i ? null : i)}>
            <div className={`p-xlarge tracking-[-0.001em] pr-6 ${expanded === i && 'font-medium'} `}>{faq.question}</div>
            <div className={`absolute top-1/2 right-0 transform -translate-y-1/2 transition-transform duration-300 w-3 h-auto ease-in-out group-hover:text-blue ${expanded === i && 'rotate-180 text-blue'} `}>
              <Icon type={IconType.ChevronDown} />
            </div>
          </header>
          <AnimatePresence>
            {expanded === i && (
              <motion.div
                className="overflow-hidden"
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.3 }}>
                <div className="pt-6" dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </motion.div>
            )}
          </AnimatePresence>
        </FadeInWhenVisible>
      ))}
    </div>
  )
}
