import React, { useEffect, useState } from "react";
import { Section } from "~/components/elements/Section";
import {
  WpPost_Article_FlexibleContent_Video,
} from "src/_generated/types";
import Image from "~/components/elements/Image";
import { Icon, IconType } from "~/components/elements/Icon";
import { ModalVideoWrapped } from "~/components/elements/ModalVideoWrapped";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Video({
  image,
  video,
  caption,
  section,
}: WpPost_Article_FlexibleContent_Video) {

  const [isOpen, setIsOpen] = useState(false)

  return (
    <FadeInWhenVisible>
      <Section {...section}>
        <div className="px-0 lg:w-[140%] lg:ml-[-40%] flex flex-col">
          <div className="relative cursor-pointer group" onClick={() => setIsOpen(true)}>
            <Image image={image} />
            <div className="absolute inset-0 bg-black opacity-50 z-[1]"></div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full bg-blue text-white flex items-center justify-center w-16 h-16 z-[2]">
              <div className="absolute inset-0 w-16 h-16 bg-blue rounded-full transition-transform duration-300 ease-in-out group-hover:scale-110" />
              <div className="w-3.5 ml-0.5 fw-icon absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Icon type={IconType.Play} />
              </div>
            </div>
          </div>
          {caption && <div className="pt-6 p-large">{caption}</div>}
        </div>

        <ModalVideoWrapped
          channel="custom"
          autoplay={1}
          isOpen={isOpen}
          url={video?.localFile?.publicURL}
          onClose={() => setIsOpen(false)}
        />
      </Section>
    </FadeInWhenVisible>
  );
}
