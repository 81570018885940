import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import InputField from '~/components/elements/forms/InputField';
import TextareaField from '~/components/elements/forms/TextareaField';
import { Icon, IconType } from '~/components/elements/Icon';
import useFormSubmit from '~/hooks/useFormSubmit';
import DatePickerField from './forms/DatePickerField';

const validationSchema = Yup.object({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  state: Yup.string().required('Required'),
  property_address: Yup.string().required('Required'),
  property_city: Yup.string().required('Required'),
  inspection_date: Yup.string().required('Required'),
});

export default function InspectionFormInner({ setIsSubmitted }) {
  const [errorMessage, setErrorMessage] = useState('');

  const { post, loading, response } = useFormSubmit({
    portalId: '5160067',
    formId: '0e024aa4-ac96-4b2c-abbf-30653d9a8339',
    setIsSubmitted,
  });

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        state: '',
        property_address: '',
        property_city: '',
        inspection_date: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await post(values);

        if (response.status === 200) {
          setIsSubmitted(true);
          console.log('success');
        } else if (response?.data?.status === 'error') {
          let str = response?.data?.message + '<br/>';
          response?.data.errors.map((err) => {
            str += '<br/>' + err.message;
          });
          setErrorMessage(str);
        }
      }}
    >
      {(props) => (
        <Form className="">
          <div className="space-y-16">
            <div className="space-y-12 max-w-[613px] mx-auto">
              <div className="space-y-6">
                <div className="flex flex-col space-y-4">
                  <InputField name="firstname" label="First Name*" />
                  <InputField name="lastname" label="Last Name*" />
                  <InputField name="phone" label="Phone Number*" />
                  <InputField name="email" label="Email*" />
                  <div className="grid md:grid-cols-2 gap-y-4 md:gap-y-0 md:gap-x-4">
                    <InputField name="property_address" label="Street Address*" />
                    <InputField name="property_city" label="City*" />
                  </div>
                  <InputField name="state" label="State*" />
                  <DatePickerField name="inspection_date" placeholderText="Preferred Date*" />
                </div>
              </div>
              <div className="flex justify-center">
                <button type="submit" className="button button--blue">
                  <span className="font-normal">Submit Request</span>
                  <span className="font-normal" aria-hidden>
                    Submit Request
                  </span>
                </button>
              </div>
            </div>
          </div>
          {errorMessage?.length > 0 && (
            <div className="mt-4 text-red" dangerouslySetInnerHTML={{ __html: errorMessage }} />
          )}
        </Form>
      )}
    </Formik>
  );
}
