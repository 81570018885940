import { graphql, useStaticQuery } from "gatsby";

const useHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        header {
          header {
            headerServicesMenu {
              link {
                title
                url
                target
              }
            }
          }
        }
      }
    }
  `);

  return data.wp;
};

export default useHeader;
