import React, { useState, useEffect } from "react";
import {
  motion,
  useMotionValue,
  useSpring,
  AnimatePresence,
} from "framer-motion";
import { Icon } from "~/components/elements/Icon";

export default function CustomCursor({
  children,
  bgColor = "black",
  ...other
}) {
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 50, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const [showCursor, setShowCursor] = useState(false);

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 68);
      cursorY.set(e.clientY - 68);
    };

    window.addEventListener("mousemove", moveCursor);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <div
      className="custom-cursor"
      onMouseEnter={() => setShowCursor(true)}
      onMouseLeave={() => setShowCursor(false)}
      {...other}
    >
      {children}
      <AnimatePresence>
        {showCursor && (
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
            className="pointer-events-none cursor"
            style={{
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
          >
            <div
              className={`w-[100px] h-[100px] bg-blue text-white rounded-full flex justify-center items-center font-moranga`}
            >
              Drag
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
