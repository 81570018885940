import React, { useState } from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Team } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Icon, IconType } from "~/components/elements/Icon";
import { Link } from 'gatsby';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Team({
  section,
  heading,
  teamMembers,
}: WpPage_FlexibleContent_FlexibleContent_Team) {
  return (
    <Section {...section}>
      <div className="container space-y-28">
        <h3 className={`text-h3 text-center`} dangerouslySetInnerHTML={{ __html: heading }} />
        <Members members={teamMembers} />
      </div>
    </Section>
  )
}

export const Members = ({members}) => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 md:gap-20 max-w-[987px] mx-auto">
      {members.map((member, i) => (
        <FadeInWhenVisible className="space-y-8" key={`teamMember${i}`} transition={{ delay: (i * .1) }}>
          <div className="aspect-w-1 aspect-h-1 bg-[#C4C4C4] rounded-full overflow-hidden relative transform transform-cpu">
            <Image image={member.image} objectFit="cover" className="!absolute" />
            <Link to={member.linkedinUrl} target="_blank" className="absolute inset-0 z-10 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out bg-black bg-opacity-50 flex items-center justify-center">
              <div className="w-8 text-white">
                <Icon type={'linkedInLetters'}  />
              </div>
            </Link>
          </div>
          <div className="space-y-1 text-center">
            <h6 className="text-h6 text-h6--small text-h6--moranga !font-normal">{member.name}</h6>
            <div className="p-large">{member.role}</div>
          </div>
        </FadeInWhenVisible>
      ))}
    </div>
  )
}
