import React, { useEffect, useState, useRef } from "react";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_Steps } from "~/_generated/types";
import { AnimatePresence, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Steps({
  layout,
  heading,
  steps,
  section,
}: WpPage_Page_FlexibleContent_Steps) {
  const [active, setActive] = useState(0);
  const contentRef = useRef();

  useEffect(() => {
    const images = document.querySelectorAll(".scrolling-image");

    const onScroll = (e) => {
      const scrollTop = e.target.documentElement.scrollTop;
      const images = document.querySelectorAll(".scrolling-image");
      const top = contentRef.current.getBoundingClientRect().top;
      if (!images) return;
      let count = 0;
      let active = 0;
      let found = false;
      for (let image of images) {
        if (top > Math.abs(scrollTop - (image.offsetTop - 300))) {
          active = count;
          found = true;
        }
        count++;
      }
      if (found) {
        setActive(active);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Section {...section}>
      <div className="container">
        <div className="hidden  md:grid grid-cols-2 gap-x-24">
          <div className="relative h-full space-y-8">
            <div className="sticky space-y-8 md:space-y-16 sticky-text">
              <h3 className="text-h3" dangerouslySetInnerHTML={{ __html: heading }} />
              <div ref={contentRef} className="">
                {steps.map((block, i) => (
                  <div key={`blocky${i}`} className={`sticky-list-item flex ${i !== steps.length - 1 && "pb-[16px]"} 
                    space-y-2 transition-opacity duration-300 ease-in-out 
                    ${active === i ? " opacity-100" : " opacity-20"}`}
                  >
                    <div className="w-[90px] flex flex-col items-center">
                      <div className="w-[90px] h-[100px]">
                        <Image image={block.icon} objectFit="contain" />
                      </div>
                      <div className={`w-0.5 transition-all duration-500 ease-in-out  ${active === i ? " h-[347px]" : " h-4"} bg-[#CCCCCC] ${active === (steps.length - 1) && '!h-0'}`}></div>
                    </div>
                    <div className="space-y-4">
                      <div className="pt-6 pl-3">
                        {block.heading && <h3 className={`text-h3 text-h3--small `} dangerouslySetInnerHTML={{ __html: block.heading }} />}
                      </div>
                      <AnimatePresence>
                        {active === i && (
                          <motion.div
                            className={`prose max-w-[385px] overflow-hidden `}
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                              open: {
                                opacity: 1,
                                height: "auto",
                                transition: {
                                  opacity: { delay: 0.3, duration: 0.4 },
                                },
                              },
                              collapsed: { opacity: 0, height: 0 },
                            }}
                            transition={{ duration: 0.3 }}
                          >
                            {block.content && <p className="max-w-[330px] text-[17px] pl-3">{block.content}</p>}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{ scrollSnapType: "y proximity" }}
            className="space-y-48 lg:space-y-60 pb-20 lg:pb-60 pt-20 lg:pt-60"
          >
            {steps.map((block, i) => (
              <Block
                key={`blockr${i}`}
                image={block.image}
                i={i}
                setActive={setActive}
              />
            ))}
          </div>
        </div>
        <div className="md:hidden">
          <div className="sticky space-y-8 md:space-y-16 top-40">
            <h3 className="text-h3" dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="space-y-16 ">
              {steps.map((block, i) => (
                <div key={`blockvert${i}`} className="space-y-8">
                  <div className="rounded-[4px]">
                    <Image image={block.image} />
                  </div>
                  <div className="space-y-2">
                    {block.heading && <h3 className={`text-h3 text-h3--small `} dangerouslySetInnerHTML={{ __html: block.heading }} />}
                    <div className={`prose max-w-[385px] overflow-hidden `}>
                      {block.content && <p className="max-w-[330px] text-[17px]">{block.content}</p>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export const Block = ({ image, i }) => {
  return (
    <div className="rounded-[4px] scrolling-image">
      <Image image={image} />
    </div>
  );
};
