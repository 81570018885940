import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Usps } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Usps({
  section,
  heading,
  content,
  usps,
  layout
}: WpPage_FlexibleContent_FlexibleContent_Usps) {
  return (
    <Section {...section}>
      <div className="container">
        {layout === 'two-col' ? (
          <UspsTwoCol heading={heading} content={content} usps={usps} />
        ) : layout === 'one-col' ? (
          <UspsOneCol heading={heading} content={content} usps={usps} />
        ) : (
          <UspsDefault heading={heading} content={content} usps={usps} />
        )}
      </div>
    </Section>
  )
}

export const UspsDefault = ({ heading, content, usps }) => {
  return (
    <div className={`space-y-16 md:space-y-24`}>
      <div className={`max-w-[856px] mx-auto text-center space-y-4`}>
        <FadeInWhenVisible transition={{ delay: 0 }}>
          <h2 className={`text-h3 usps-heading`} dangerouslySetInnerHTML={{ __html: heading }} />
        </FadeInWhenVisible>
        <FadeInWhenVisible transition={{ delay: .2 }}>
          <div className={`p-large usps-content max-w-[820px] mx-auto`} dangerouslySetInnerHTML={{ __html: content }} />
        </FadeInWhenVisible>
      </div>
      <div className="grid gap-y-20 md:gap-y-24 md:grid-cols-3 md:gap-x-8">
        {usps.map((usp, i) => (
          <FadeInWhenVisible transition={{ delay: i * .2 }} key={`uspdefault${i}`}>
            <Usp {...usp}  i={i} />
          </FadeInWhenVisible>
        ))}
      </div>
    </div>
  )
}

export const UspsTwoCol = ({ heading, content, usps }) => {
  return (
    <div className="space-y-12 md:space-y-0 md:grid grid-cols-12">
      <div className="col-span-5 space-y-8">
        <FadeInWhenVisible>
          <h2 className={`text-h3`} dangerouslySetInnerHTML={{ __html: heading }} />
          <div className={`p-xlarge`} dangerouslySetInnerHTML={{ __html: content }} />
        </FadeInWhenVisible>
      </div>
      <div className="md:col-span-7 md:col-start-7 lg:col-span-6 lg:col-start-7">
        <div className="space-y-8">
          {usps.map((usp, i) => (
            <UspAlt {...usp} key={`usptwocol${i}`} />
          ))}
        </div>
      </div>
    </div>
  )
}

export const UspsOneCol = ({ heading, content, usps }) => {
  return (
    <div className="space-y-12 md:space-y-24 max-w-[600px] mx-auto">
      <FadeInWhenVisible className="col-span-5 space-y-8">
        <h2 className={`text-h1 text-h1--alt text-center`} dangerouslySetInnerHTML={{ __html: heading }} />
      </FadeInWhenVisible>
      <div className="space-y-8 md:space-y-20">
        {usps.map((usp, i) => (
          <UspAltTwo {...usp} key={`uspaltTwo${i}`} />
        ))}
      </div>
    </div>
  )
}

export const Usp = ({ heading, content, image, i }) => {


  return (
    <FadeInWhenVisible className="max-w-[338px] mx-auto text-center space-y-10 md:space-y-[58px]">
      <div className="w-[120px] h-[90px] mx-auto">
        <Image image={image} objectPosition="center bottom" className="h-full w-full" />
      </div>
      <div className="space-y-5">
        <div>
          <h6 className="text-h5 leading-[1.07]  max-w-[236px] mx-auto " dangerouslySetInnerHTML={{ __html: heading }} />
        </div>
        <div className="text-[17px] leading-[1.4]">{content}</div>
      </div>
    </FadeInWhenVisible>
  )
}

export const UspAlt = ({ heading, content, image }) => {
  return (
    <FadeInWhenVisible className="flex space-x-10 items-center">
      <div className="w-20 h-20">
        <Image image={image} objectFit="contain" objectPosition="center" className="h-full w-full" />
      </div>
      <div className="space-y-2">
        <h6 className="text-h5" dangerouslySetInnerHTML={{ __html: heading }} />
        <div className="p-xlarge">{content}</div>
      </div>
    </FadeInWhenVisible>
  )
}

export const UspAltTwo = ({ heading, content, image }) => {
  return (
    <FadeInWhenVisible className="flex space-x-16 items-center">
      <div className="w-[82px] h-[62px] md:w-[163px] md:h-[123px] flex-auto flex-shrink-0 flex-grow-0">
        <Image image={image} objectFit="contain" objectPosition="center" className="h-full w-full" />
      </div>
      <div className="space-y-4">
        <h6 className="text-h5 !font-body !font-medium" dangerouslySetInnerHTML={{ __html: heading }} />
        <div className="text-[17px]">{content}</div>
      </div>
    </FadeInWhenVisible>
  )
}