import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function ProgressSlides({section, heading, content, slides,}: 
  WpPage_FlexibleContent_FlexibleContent_ProgressSlides) {

  const [active, setActive] = useState(null);
  const [swiper, setSwiper] = useState(null);

  const interval = useRef(null);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });


  useEffect(() => {

    if (inView) {
      setActive(0);
      interval.current = setInterval(() => {
        setActive((i) => (i + 1) % slides.length);
      }, 5000);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [interval, inView]);

  const overrideActive = (i) => {
    setActive(i);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActive((i) => (i + 1) % slides.length);
    }, 5000);
  };

  const slideTo = (index) => swiper?.slideTo(index);

  useLayoutEffect(() => {
    slideTo(active + 1);
  }, [active]);

  return (
    <Section {...section}>
      <div className="container" ref={ref}>
        <div className="lg:flex items-center space-y-12 lg:space-y-0 lg:space-x-32">
          <div className="lg:w-4/12 space-y-12 md:space-y-28">
            <div className="space-y-6">
              <FadeInWhenVisible>
                <h3 className="text-h3 text-h3--alt" dangerouslySetInnerHTML={{ __html: heading }} />
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <p className="">
                  {content}
                </p>
              </FadeInWhenVisible>
            </div>
            <FadeInWhenVisible className="flex flex-col space-y-5" >
              {slides.map((slide, i) => (
                <button className={`py-3 border-b border-black border-opacity-30 relative `} onClick={() => overrideActive(i)} key={`progresslabel${i}`}>
                  <h6 className="text-h6 text-h6--alt text-left" dangerouslySetInnerHTML={{ __html: slide.label }} />
                  <div className={`absolute bottom-[-1px] left-0 bg-blue h-px transition-all  ${active === i ? 'w-full duration-[5s] ease-linear hid' : 'w-0 duration-[0s]'} `}></div>
                </button>
              ))}
            </FadeInWhenVisible>
          </div>
          <FadeInWhenVisible className="w-full lg:w-[63%] lg:ml-auto bg-lime py-8 pb-2 md:pb-20 md:py-20 relative">
            <StaticImage src={'../../assets/images/progress-slides-bg.jpg'} className="!absolute inset-0" alt="" objectFit="cover" />

            <Swiper
              onSwiper={setSwiper} modules={[A11y, Pagination]}
              className={`w-full swiper-pagination-light !pb-6 md:!pb-0`}
              slidesPerView={1} spaceBetween={16} loop={true}
              pagination={{clickable: true}}>
              {slides.map((slide, i) => (
                <SwiperSlide key={`progressslide${i}`}>
                  <Image image={slide.image} />
                </SwiperSlide>
              ))}
            </Swiper>
          </FadeInWhenVisible>
        </div>
      </div>
    </Section>
  )
}
