import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_ComparisonTable } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Icon, IconType } from "~/components/elements/Icon";
import { StaticImage } from 'gatsby-plugin-image';
import Tick from '~/components/elements/Tick';

export default function ComparisonTable({
  section,
  heading,
  content,
  column1,
  column2
}: WpPage_FlexibleContent_FlexibleContent_ComparisonTable) {
  return (
    <Section {...section}>
      <div className="container space-y-16">
        <div className="space-y-6 text-center max-w-[653px] mx-auto relative">
          {heading && <h2 className={`text-h3 `} dangerouslySetInnerHTML={{ __html: heading }} />}
          {content && <div className={`p-large`} dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
        <div className="max-w-[995px] mx-auto gap-x-2.5 relative">
          <StaticImage src={'../../assets/images/table-bg-1.png'} className="hidden lg:block !absolute -left-20 -top-24 w-20" alt="" />
          <StaticImage src={'../../assets/images/table-bg-2.png'} className="hidden lg:block !absolute -right-8 -top-56 w-40" alt="" />
          <ColumnCards column1={column1} column2={column2} />
        </div>
      </div>
    </Section>
  )
}

export const ColumnCards = ({ column1, column2 }) => {
  return (
    <div className="">
      <div className="hidden md:grid md:grid-cols-2 gap-2.5">
        <div className="space-y-5">
          <div className="bg-[#494A5A] text-white p-xlarge font-medium py-3.5 text-center rounded-tl-xl">
            {column1.heading}
          </div>
        </div>
        <div className="space-y-5">
          <div className="bg-blue text-white p-xlarge font-medium py-3.5 text-center rounded-tr-xl">
            {column2.heading}
          </div>
        </div>
        {column1.rows.map((row, i) => (
          <>
            <Card {...row} />
            <Card {...column2.rows[i]} tick />
          </>
        ))}
      </div>
      <div className="block md:hidden space-y-2.5">
        <div className="space-y-5">
          <div className="bg-[#494A5A] text-white p-xlarge font-medium py-3.5 text-center rounded-t-xl">
            {column1.heading}
          </div>
        </div>
        {column1.rows.map((row, i) => (
          <Card {...row} key={`comparisonColumn1Card${i}`} />
        ))}
         <div className="space-y-5">
          <div className="bg-blue text-white p-xlarge font-medium py-3.5 text-center rounded-t-xl">
            {column2.heading}
          </div>
        </div>
        {column2.rows.map((row, i) => (
          <Card {...row} tick key={`comparisonColumn2Card${i}`} />
        ))}
      </div>
    </div>
  )
}


export const Card = ({ content, tick = false }) => {
  return (
    <div className="rounded-[7px] comparison-table-card flex items-center py-7 px-10 bg-white">
      <div className="flex items-center space-x-4">
        {tick && (
          <Tick />
        )}
        <div className="pt-1.5 p-large leading-[1.67]">
          {content}
        </div>
      </div>
    </div>
  )
}

