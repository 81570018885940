import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Features } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Features({
  section,
  heading,
  content,
  features,
  layout
}: WpPage_FlexibleContent_FlexibleContent_Features) {
  return (
    <Section {...section}>
      <div className="container">
        <div className={`space-y-16 ${layout === 'cards' ? 'md:space-y-24' : 'md:space-y-40'}`}>
          {(heading || content) && (
            <div className={`${layout === '2' ? 'max-w-[1077px]' : 'max-w-[856px]'}  mx-auto text-center space-y-4 features-heading-container`}>
              <FadeInWhenVisible>
                <h2 className={`features-heading ${layout === '2' ? 'text-h2' : 'text-h3'}`} dangerouslySetInnerHTML={{ __html: heading }} />
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <div className={`p-large features-content ${layout === '2' ? 'max-w-[672px] mx-auto' : ''}`} dangerouslySetInnerHTML={{ __html: content }} />
              </FadeInWhenVisible>
            </div>
          )}
          {layout === 'circles' ? (
            <div className="grid space-y-20 md:space-y-0 md:grid-cols-3">
              {features.map((feature, i) => (
                <FadeInWhenVisible transition={{ delay: (i * .1) }}>
                  <Feature {...feature} key={`feature${i}`} />
                </FadeInWhenVisible>
              ))}
            </div>
          ) : (
            <div className="grid gap-6 md:space-y-0 md:grid-cols-2 max-w-[1077px] mx-auto">
              {features.map((feature, i) => (
                <FadeInWhenVisible>
                  <FeatureCard {...feature} key={`feature${i}`} transition={{ delay: (i % 2 === 0 && 1) }}/>
                </FadeInWhenVisible>
              ))}
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

export const Feature = ({ page }) => {

  if (!page || !page[0]) return null

  let hoverColour = '';

  switch (page[0]?.servicesPage?.colour) {
    case "blue":
      hoverColour = "group-hover:bg-teal";
      break;
    case "orange":
      hoverColour = "group-hover:bg-orange";
      break;
    case "lime":
      hoverColour = "group-hover:bg-lime";
      break;
    case "aero":
      hoverColour = "group-hover:bg-aero";
      break;
  }

  return (
    <Link to={page[0]?.uri} className="block group">
      <div className="max-w-[338px] mx-auto text-center flex flex-col h-full">
        <div className="space-y-10 md:space-y-[58px] mb-5">
          <div className={`w-64 h-64 relative mx-auto transition-colors duration-300 ease-in-out bg-beige flex items-center justify-center rounded-full  ${hoverColour}`}>
            <div className="w-[100px]">
              <Image image={page[0]?.servicesPage?.icon} objectPosition="center bottom" className="h-full w-full" />
            </div>
            <StaticImage src={'../../assets/images/feature-circle-bg.png'} className="!absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out" alt="" />
          </div>
          <div className="space-y-5 flex-auto">
            <h4 className="text-h4 max-w-[236px] mx-auto !font-normal" dangerouslySetInnerHTML={{ __html: page[0]?.title }} />
            <div className="text-[17px]">{page[0]?.servicesPage?.summary}</div>
          </div>
        </div>
        <div className="mt-auto">
          <Button link={{ url: page[0]?.uri, title: "Learn more" }} type={ButtonType.Text} />
        </div>
      </div>

    </Link>
  )
}

export const FeatureCard = ({ page }) => {

  if (!page || !page[0]) return null

  let hoverColour = '';

  switch (page[0]?.servicesPage?.colour) {
    case "blue":
      hoverColour = "hover:bg-teal";
      break;
    case "orange":
      hoverColour = "hover:bg-orange";
      break;
    case "lime":
      hoverColour = "hover:bg-lime";
      break;
    case "aero":
      hoverColour = "hover:bg-aero";
      break;
  }

  return (

    <Link to={page[0]?.uri} className={`block bg-beige py-[40px] pb-24 md:py-[80px] md:pb-[71px] px-8 md:px-20 text-center rounded-[7px] feature-card-shadow relative group transition-colors duration-300 ease-in-out ${hoverColour}`}>
      <StaticImage src={'../../assets/images/feature-card-bg.png'} className="!absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out" alt="" />
      <div className="max-w-[354px] h-full mx-auto  space-y-10 md:space-y-[58px] relative">
        <div className="w-[150px] h-[122px]  mx-auto">
          <Image image={page[0]?.servicesPage?.icon} objectPosition="center" className="h-full w-full" />
        </div>
        <div className="space-y-20">
          <div className="space-y-5">
            <h4 className="text-h4 max-w-[236px] mx-auto !font-normal" dangerouslySetInnerHTML={{ __html: page[0]?.title }} />
            <div className="text-[17px] leading-[1.4] max-w-[270px] mx-auto">{page[0]?.servicesPage?.summary}</div>
          </div>

        </div>
      </div>
      <div className="mt-12 relative">
        <Button link={{ title: "Learn more" }} type={ButtonType.Text} />
      </div>

    </Link>
  )
}
