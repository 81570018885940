import React, { useState } from 'react';
import { Section } from '~/components/elements/Section';
import InspectionFormInner from '~/components/elements/InspectionFormInner';
import { Confirmation } from './Contact';

export default function InspectionForm({
  section,
  heading,
  content,
  confirmation,
}: WpPage_FlexibleContent_FlexibleContent_InspectionForm) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className="relative">
      <Section {...section}>
        <div className="container space-y-16">
          {!isSubmitted && (
            <div className="space-y-6 max-w-[800px] mx-auto text-center">
              <h1
                className={`text-h1-large text-span-blue text-center`}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              {content && <p className="p-large max-w-[630px] mx-auto">{content}</p>}
            </div>
          )}
          {!isSubmitted ? <InspectionFormInner setIsSubmitted={setIsSubmitted} /> : <Confirmation {...confirmation} />}
        </div>
      </Section>
    </div>
  );
}
