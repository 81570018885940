import { useField } from 'formik';
import React from 'react';
import { Icon, IconType } from '~/components/elements/Icon';

export default function SelectField(props) {
  const { label, placeholder, options, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;
  return (
    <label className="relative flex flex-col items-start flex-auto text-black md:hidden ">
      <div className={` mb-4`}>{label}</div>
      <select
        className={`${
          props?.className
        } bg-beige font-moranga font-light w-full p-xlarge-alt flex items-center px-3 py-2 rounded-[5px] appearance-none   ${
          isInvalid && 'border-red'
        } `}
        defaultValue={options[0].value}
        {...field}
        {...other}
      >
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="flex absolute top-[43%] right-[12px] items-center justify-center w-6 h-6 ml-auto text-white transition-colors duration-300 ease-in-out rounded-full bg-navy group-hover:bg-navy">
        <div className={`w-[10px] fw-icon transition-transform duration-300 ease-in-out transform rotate-90 `}>
          <Icon type={IconType.ArrowRight} />
        </div>
      </div>
    </label>
  );
}
