import React, { useState } from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Careers } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Icon, IconType } from "~/components/elements/Icon";
import { Link } from 'gatsby';

export default function Careers({
  section,
  heading,
  departments,
  cta
}: WpPage_FlexibleContent_FlexibleContent_Careers) {
  return (
    <Section {...section}>
      <div className="container space-y-12 md:space-y-20">
        <div className="space-y-12 md:space-y-40">
          <h3 className={`text-h3 `} dangerouslySetInnerHTML={{ __html: heading }} />
          <div className="space-y-12 md:space-y-16">
            {departments.map((department, i) => (
              <Department {...department} key={`careersDepartment${i}`} />
            ))}
          </div>
        </div>
        <Cta {...cta} />
      </div>
    </Section>
  )
}

export const Department = ({ heading, careers }) => {
  return (
    <div>
      <h5 className="text-h5 text-h5--moranga border-b border-[#989898] border-opacity-30 pb-8" dangerouslySetInnerHTML={{ __html: heading }} />
      {careers.map((career, i) => (
        <Career {...career} key={`department${heading}Career${i}`} />
      ))}
    </div>
  )
}

export const Career = ({ role, location, link }) => {
  return (
    <a href={link} target="_blank" className="border-b border-[#989898] border-opacity-30 py-8 flex items-center group">
      <div className="font-medium p-large leading-none pt-0.5">{role}</div>
      <div className="space-x-16 ml-auto flex items-center">
        <div className="leading-none">{location}</div>
        <div className="w-8 h-8 rounded-full bg-blue text-white flex justify-center items-center group-hover:bg-navy transition-colors duration-300 ease-in-out">
          <div className="w-[14px] fw-icon">
            <Icon type={IconType.ArrowRight} />
          </div>
        </div>
      </div>
    </a>
  )
}

export const Cta = ({link}) => {
  return (
    <Link to={link.url} className="px-12 py-7 bg-navy text-white hover:bg-blue transition-colors duration-300 ease-in-out w-full flex items-center">
      <div className="font-medium leading-none">{link.title}</div>
      <div className="ml-auto">
        <Icon type={IconType.ArrowRight} />
      </div>
    </Link>
  )
}
