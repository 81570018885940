import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_TwoColumnsAlt } from "~/_generated/types";
import Image from '~/components/elements/Image';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'


export default function TwoColumnsAlt({
  column1,
  column2,
  section,
}: WpPage_Page_FlexibleContent_TwoColumnsAlt) {

  return (
    <Section {...section}>
      <div className="container">
        <div className="grid gap-20 md:grid-cols-2 md:gap-8">

          <Column {...column1} />
          <Column {...column2} delay={.2} />
        </div>
      </div>
    </Section>
  );
}

export const Column = ({image, heading, content, delay = 0}) => {
  return (
    <FadeInWhenVisible className="space-y-12" transition={{delay: delay }}>
      <Image image={image} />
      <div className="space-y-8">
        <h3 className="text-h3 !leading-none max-w-[482px]" dangerouslySetInnerHTML={{__html: heading}} />
        <div className="p-xlarge max-w-[530px]" dangerouslySetInnerHTML={{__html: content}} />
      </div>
    </FadeInWhenVisible>
  )
}
