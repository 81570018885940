import React, { useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import InputField from '~/components/elements/forms/InputField';
import TextareaField from '~/components/elements/forms/TextareaField';
import { Icon, IconType } from '~/components/elements/Icon';
import useFormSubmit from '~/hooks/useFormSubmit';
import MultiOptionSelect from './forms/MultiOptionSelectField';
import SelectField from './forms/SelectField';

const validationSchema = Yup.object({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone: Yup.string(),
  inquiry_type: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});

export default function ContactForm({ setIsSubmitted }) {
  const ref = useRef();

  const [errorMessage, setErrorMessage] = useState('');
  const [active, setActive] = useState('Sales');

  const tabs = ['Sales', 'Support', 'Investor Relations', 'Other'];

  const { post, loading, response } = useFormSubmit({
    portalId: '5160067',
    formId: '7fb3f70c-988a-46e0-aa8a-484b8618d788',
    setIsSubmitted,
  });

  return (
    <Formik
      innerRef={ref}
      enableReinitialize={true}
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
        inquiry_type: active,
        phone: '',
        message: active === 'Other' ? '' : 'no message',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setFieldValue }) => {
        await post(values);

        if (response.status === 200) {
          setIsSubmitted(true);
          console.log('success');
        } else if (response?.data?.status === 'error') {
          let str = response?.data?.message + '<br/>';
          response?.data.errors.map((err) => {
            str += '<br/>' + err.message;
          });
          setErrorMessage(str);
        }
      }}
    >
      {(props) => {
        setActive(props?.values?.inquiry_type);
        return (
          <Form className="">
            <div className="space-y-16">
              <div className="max-w-[662px] mx-auto  ">
                <div className="space-y-12 max-w-[613px] mx-auto">
                  <div className="pt-12 space-y-6 border-t border-t-black border-opacity-20">
                    <div className="font-medium text-[17px] text-center">How can we help you?</div>
                    <div className="flex flex-col !-mt-4 md:!mt-4 space-y-4">
                      <MultiOptionSelect
                        name="inquiry_type"
                        options={[
                          {
                            label: 'Sales',
                            value: 'Sales',
                          },
                          {
                            label: 'Support',
                            value: 'Support',
                          },
                          {
                            label: 'Investors',
                            value: 'Investor Relations',
                          },
                          {
                            label: 'Other',
                            value: 'Other',
                          },
                        ]}
                      />

                      <SelectField options={tabs} name="inquiry_type" />
                      <InputField name="firstname" label="First Name*" />
                      <InputField name="lastname" label="Last Name*" />
                      <InputField name="email" label="Email*" />
                      <InputField name="phone" label="Phone Number" />

                      {props?.values?.inquiry_type !== 'Other' ? (
                        <></>
                      ) : (
                        <TextareaField name="message" label="What would you like to know?*" />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button type="submit" className="button button--blue">
                      <span className="font-normal">Send Enquiry</span>
                      <span className="font-normal" aria-hidden>
                        Send Enquiry
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {errorMessage?.length > 0 && (
                <div className="mt-4 text-red" dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
