import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";

import "swiper/css";

import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { WpPage_FlexibleContent_FlexibleContent_Tabs } from '~/_generated/types';
import { AnimatePresence, motion } from "framer-motion";
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import { useInView } from "react-intersection-observer";
import { StaticImage } from "gatsby-plugin-image";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'


export default function Tabs({
  section,
  heading,
  tabs,
  tabsBackgroundColour,
  layout
}: WpPage_FlexibleContent_FlexibleContent_Tabs) {

  const [active, setActive] = useState(null);

  const interval = useRef(null);

  const [ref, disableTempinView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const inView = true;

  useEffect(() => {

    if (inView && layout !== 'services') {
      setActive(0);
      interval.current = setInterval(() => {
        setActive((i) => (i + 1) % tabs.length);
      }, 5000);
    } else {
      setActive(0);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [interval, inView]);

  const overrideActive = (i) => {
    setActive(i);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActive((i) => (i + 1) % tabs.length);
    }, 5000);
  };

  return (
    <Section {...section}>
      <div className={`container ${layout === 'services' ? 'space-y-12' : 'space-y-12 md:space-y-24'}`}>
        <FadeInWhenVisible className="max-w-[727px] mx-auto text-center">
          <h2 className="text-h2 !leading-[1.03]" dangerouslySetInnerHTML={{ __html: heading }} />
        </FadeInWhenVisible>
        {layout === 'services' ? (
          <FadeInWhenVisible className="space-y-10" ref={ref}>
            <div className="max-w-[1032px] mx-auto">
              <ProgressTabsAlt tabs={tabs} active={active} setActive={setActive} overrideActive={overrideActive} />
            </div>
            <SlidesAlt tabs={tabs} active={active} tabsBackgroundColour={tabsBackgroundColour} />
          </FadeInWhenVisible>
        ) : (
          <FadeInWhenVisible className="max-w-[996px] mx-auto space-y-16" ref={ref}>
            <ProgressTabs tabs={tabs} active={active} setActive={setActive} overrideActive={overrideActive} />
            <Slides tabs={tabs} active={active} />
          </FadeInWhenVisible>
        )}
      </div>
    </Section >
  )
}

export const ProgressTabs = ({ tabs, active, setActive, overrideActive }) => {
  return (
    <div className="flex space-x-3.5 justify-between">
      {tabs.map((tab, i) => (
        <button key={`tabbedbutton${i}`} className={`text-center border-b border-navy-dark border-opacity-20 flex-1 cursor-pointer appearance-none py-1 relative ${active === i && 'font-medium'}`} onClick={() => overrideActive(i)}>
          {tab.label}
          <div className={`absolute bottom-[-1px] left-0 bg-navy-dark h-px transition-all  ${active === i ? 'w-full duration-[5s] ease-linear' : 'w-0 duration-[0s]'} `}></div>
        </button>
      ))}
    </div>
  )
}

export const Slides = ({ tabs, active }) => {

  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => swiper?.slideTo(index);

  useLayoutEffect(() => {
    slideTo(active);
  }, [active]);


  return (
    <div className="lg:grid grid-cols-12 space-y-12 lg:space-y-0 items-center">
      <div className="col-span-5">
        <AnimatePresence>
          {tabs?.map((slide, i) => (
            <div key={`tabbedswiperslide${i}`}>
              {i === active && (
                <motion.div
                  transition={{ duration: 0.5 }}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 10, opacity: 0 }}
                >
                  <div className="lg:max-w-[443px] ml-auto space-y-8 pl-7 pr-16">
                    <h3 className="text-h3 text-h3--alt text-h3--moranga" dangerouslySetInnerHTML={{ __html: slide.content.heading }} />
                    <p className="text-[17px]">{slide.content.content}</p>
                    <Button link={slide.content.link.link} type={slide.content.link.type} />
                  </div>
                </motion.div>
              )}
            </div>
          ))}
        </AnimatePresence>

      </div>
      <div className="col-span-7">
        <Swiper
          onSwiper={setSwiper}
          modules={[A11y]}
          className={`w-full`}
          slidesPerView={1}
          draggable={false}
          allowTouchMove={false}
        >
          {tabs.map((slide, i) => (
            <SwiperSlide
              key={`tabbedswiper${i}`}
              style={{ height: "auto" }}
            >
              <Image
                image={slide?.image}
                className="w-[102%] h-[102%]"
                objectFit="cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}


export const ProgressTabsAlt = ({ tabs, active, setActive, overrideActive }) => {
  return (
    <div className="grid grid-cols-2 gap-2 md:gap-3 md:gap md:flex  md:space-y-0 md:flex-row md:space-x-3.5 justify-between">
      {tabs.map((tab, i) => (
        <button key={`tabbedbutton${i}`} className={`rounded-[68px] transition-colors duration-300 ease-in-out hover:bg-blue hover:text-white text-center border border-navy-dark border-opacity-10 flex-1 cursor-pointer appearance-none py-6 pb-5 leading-none relative text-[14px] md:text-[17px] font-medium ${active === i && 'bg-blue text-white'}`} onClick={() => setActive(i)}>
          {tab.label}
        </button>
      ))}
    </div>
  )
}

export const SlidesAlt = ({ tabs, active, tabsBackgroundColour }) => {

  let bg = "";
  switch (tabsBackgroundColour) {
    case "blue":
      bg = "bg-teal";
      break;
    case "orange":
      bg = "bg-orange";
      break;
    case "lime":
      bg = "bg-lime";
      break;
    case "aero":
      bg = "bg-aero";
      break;
  }

  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => swiper?.slideTo(index);

  useLayoutEffect(() => {
    slideTo(active);
  }, [active]);

  return (
    <div className={`${bg} py-12 md:py-24 relative overflow-hidden px-4 md:px-8`}>
      <Swiper
        onSwiper={setSwiper}
        modules={[A11y]}
        className={`w-full`}
        slidesPerView={1}
        allowTouchMove={false}
      >
        {tabs.map((slide, i) => (
          <SwiperSlide key={`tabbedswiper${i}`} className="h-full">
            <div className="bg-white px-4 md:px-10 py-10 md:py-16 text-center max-w-[476px] mx-auto tab-slide space-y-16">
              <div className="w-[130px] h-[110px] mx-auto">
                <Image image={slide?.image} objectFit="contain" objectPosition="center" />
              </div>
              <div className="space-y-8">
                <h3 className="text-h5" dangerouslySetInnerHTML={{ __html: slide.content.heading }} />
                <p className="p-xlarge-alt">{slide.content.content}</p>
                <Button link={slide.content.link.link} type={slide.content.link.type} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <StaticImage src={'../../assets/images/tabs-bg-1.png'} className="!absolute top-0 left-0  w-28" alt="" />
      <StaticImage src={'../../assets/images/tabs-bg-2.png'} className="!absolute right-0 bottom-0 w-32" alt="" />
    </div>
  )
}
