import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_TeamSlider } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, FreeMode } from "swiper";
import CustomCursor from "~/components/elements/CustomCursor";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'


import "swiper/css";

export default function TeamSlider({
  section,
  slides,
}: WpPage_FlexibleContent_FlexibleContent_TeamSlider) {
  return (
    <div className="overflow-hidden">
      <Section {...section}>
        <FadeInWhenVisible className="container">
          <div className="flex flex-col md:flex-row whitespace-nowrap items-start md:pl-[0] relative px-5 md:px-0">
            <div className="md:absolute inset-0 text-navy flex justify-center items-center ">
              <div className="text-navy font-moranga text-[12vw] md:text-[19vw]">The Team</div>
            </div>
            <CustomCursor >
              <Swiper
                modules={[A11y, FreeMode]}
                freeMode
                className={`w-full md:max-w-[600px] md:!mx-auto !overflow-visible z-10 relative mr-[-25vw]`}
                slidesPerView={'auto'}
                style={{paddingTop: '2rem', paddingBottom: '2rem'}}
              >
                {slides.map((slide, i) => (
                  <SwiperSlide
                    key={`teamslide${i}`}
                    style={{ height: "auto" }}
                    className="!max-w-[300px] md:!max-w-[600px] pr-40 last:pr-0 box-content" 
                  >
                    <div className={`border-[5px] border-white team-slider-card w-full md:!w-[410px] relative flex-shrink-0 transform pointer-events-none ${i % 2 === 0 ? '-rotate-6' : 'rotate-6'}`}>
                      <Image image={slide.image} />
                      <div className={`absolute bottom-4 left-8 `}>
                        <div className={`text-h6 text-h6--small text-h6--moranga !text-white`}>{slide.name}</div>
                        <div className={`p-large opacity-60 text-white`}>{slide.role}</div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </CustomCursor>
          </div>
        </FadeInWhenVisible>
      </Section>
    </div>
  )
}
