import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_TwoColumns } from "~/_generated/types";
import Image from '~/components/elements/Image';
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function TwoColumns({
  contentgroup,
  image,
  section,
  reverse,
  layout
}: WpPage_Page_FlexibleContent_TwoColumns) {

  return (
    <Section {...section}>
      <div className="container">
        <div className="grid space-y-10 md:space-y-0 md:grid-cols-12 items-center">
          <div className={`md:col-span-5 tc-content-container ${reverse ? 'md:col-start-8 md:order-1 lg:pr-[60px]' : 'lg:pl-6'}`}>
            <FadeInWhenVisible className="space-y-12 text-center lg:text-left">
              <div className="space-y-5">
                <h3 className="text-h3 !leading-[1.125] mx-auto lg:ml-0 tc-heading" dangerouslySetInnerHTML={{ __html: contentgroup.heading }} />
                <div className="p-xlarge max-w-[395px] mx-auto md:mx-0 tc-content" dangerouslySetInnerHTML={{ __html: contentgroup.content }} />
              </div>
              {contentgroup.link.link && (
                <Button link={contentgroup.link.link} type={contentgroup.link.type} />
              )}
              {layout === 'stats' && (
                <div className="border-t-navy border-t border-opacity-30 py-10 grid grid-cols-2 gap-x-4 ">
                  {contentgroup.stats.map((stat, i) => (
                    <div className="space-y-1">
                      <div className="text-h3 text-h3--moranga">{stat.value}</div>
                      <div className="p-xlarge">{stat.label}</div>
                    </div>
                  ))}
                </div>
              )}
            </FadeInWhenVisible>
          </div>
          <div className={`md:col-span-5 tc-image ${reverse ? 'md:col-start-1' : 'md:col-start-8'} `}>
            <FadeInWhenVisible transition={{delay: .2}} >
              <Image image={image} />
            </FadeInWhenVisible>
          </div>
        </div>
      </div>
    </Section>
  );
}
