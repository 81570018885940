import { useField } from 'formik';
import React from 'react';

export default function InputField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`flex flex-col text-black items-start flex-auto relative  ${props.className || ''}`}>
      <label className={`screenreader-text`}>{label}</label>
      <input
        placeholder={label}
        className={`border rounded-sm border-navy-dark border-opacity-20 text-black bg-transparent w-full px-4 py-4 text-[17px] outline-none focus:border-opacity-100 transition-colors  ${
          isInvalid && 'border border-error !border-opacity-100 '
        }`}
        {...field}
        {...other}
      />
      {isInvalid && <ErrorMessage className=" top-[94%]" error={meta.error} />}
    </div>
  );
}

export const ErrorMessage = ({ error = '', className = '' }) => {
  return (
    <div className={`text-[10px] text-error flex items-center mt-1 text-red absolute ${className}  `}>{error}</div>
  );
};
