import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Comparison } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"

import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import { StaticImage } from 'gatsby-plugin-image';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'


export default function Comparison({
  section,
  contentGroup,
  cards,
  layout
}: WpPage_FlexibleContent_FlexibleContent_Comparison) {
  return (
    <div className="relative">
      <Section {...section}>
        <div className="container">
          <div className={`space-y-10 lg:space-y-0 lg:flex ${layout === '2' && 'bg-navy text-white p-8 py-16 md:p-24 md:py-32 md:pb-36 relative items-center'}`}>
            <FadeInWhenVisible className={`${layout === '2' ? 'lg:w-5/12' : 'lg:w-5/12'} `}>
              <div className={`space-y-5 ${layout === '2' ? 'text-center lg:text-left' : 'md:pt-10'} `}>
                {contentGroup?.heading && <h3 className={`${layout === '2' ? 'text-h3 !leading-[.94]' : 'text-h2 !leading-[1.17]'} comparison-heading !leading-[.94] text-center lg:text-left`} dangerouslySetInnerHTML={{ __html: contentGroup.heading }} />}
                {contentGroup?.content && <p className={`p-xlarge ${layout === '2' && 'mx-auto lg:ml-0 max-w-[390px]'}`}>{contentGroup.content}</p>}
                {contentGroup?.link && <Button link={contentGroup.link.link} type={contentGroup.link.type} />}
              </div>
            </FadeInWhenVisible>
            <FadeInWhenVisible className={`ml-auto ${layout === '2' ? 'lg:w-1/2' : 'lg:w-[51%]'}`}>
              <Cards cards={cards} layout={layout} />
            </FadeInWhenVisible>
            {layout === '2' && (<StaticImage src={'../../assets/images/services-cta-bg.png'} className="!absolute left-0 bottom-0 right-0" alt="" />)}
          </div>

        </div>
      </Section>
      {layout === '1' && (
        <>
          <StaticImage src={'../../assets/images/comparison-bg-1.png'} className="!absolute top-0 left-0  w-16" alt="" />
          <StaticImage src={'../../assets/images/comparison-bg-2.png'} className="!absolute right-0 bottom-0 w-32" alt="" />
        </>
      )}
    </div>
  )
}


export const Cards = ({ cards, layout }) => {
  return (
    <div>
      <Swiper
        modules={[A11y, Pagination]}
        className={`w-full swiper-pagination-dark ${(cards.length > 1 && '!pb-12 -mb-12')}`}
        slidesPerView={1}
        spaceBetween={16}
        pagination={{clickable: true}}
        
      >
        {cards.map((card, i) => (
          <SwiperSlide key={`tabbedswiper${i}`}>
            <div className="space-y-4 grid auto-rows-fr">
              <Card card={card.card1} layout={layout} />
              <Card card={card.card2} layout={layout} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export const Card = ({ card, layout }) => {
  return (
    <div className={`bg-white text-[#494A5A] space-y-3 flex flex-col justify-center px-8  md:p-16 ${layout === '2' ? 'py-10 md:py-10' : 'pt-[52px] pb-10 rounded-[7px]'}`}>
      <h5 className={`${layout === '2' ? 'text-h6--alt' : 'text-h4--small'}  !text-navy-dark`} dangerouslySetInnerHTML={{ __html: card.heading }} />
      <p className={`${layout === '2' ? 'text-[15px] leading-[1.6]' : 'p-xlarge leading-normal'} `}>{card.content}</p>
    </div>
  )
}
