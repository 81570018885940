import React from "react";
import TwoColumns from "~/components/flexible/TwoColumns";
import Masthead from "~/components/flexible/Masthead";
import Features from "~/components/flexible/Features";
import Process from "~/components/flexible/Process";
import Tabs from "~/components/flexible/Tabs";
import Testimonial from "~/components/flexible/Testimonial";
import Intro from "~/components/flexible/Intro";
import Faqs from "~/components/flexible/Faqs";
import Cta from "~/components/flexible/Cta";
import Steps from "~/components/flexible/Steps";
import FullWidthImage from "~/components/flexible/FullWidthImage";
import Team from "~/components/flexible/Team";
import Comparison from "~/components/flexible/Comparison";
import TabsAlt from "~/components/flexible/TabsAlt";
import Usps from "~/components/flexible/Usps";
import ContentWithImage from "~/components/flexible/ContentWithImage";
import Careers from "~/components/flexible/Careers";
import TwoColumnsAlt from "~/components/flexible/TwoColumnsAlt";
import Contact from "~/components/flexible/Contact";
import ProgressSlides from "~/components/flexible/ProgressSlides";
import ServicesCta from "~/components/flexible/ServicesCta";
import Trustpilot from "~/components/flexible/Trustpilot";
import ComparisonTable from "~/components/flexible/ComparisonTable";
import Values from "~/components/flexible/Values";
import Text from "~/components/flexible/Text";
import Image from "~/components/flexible/Image";
import Video from "~/components/flexible/Video";
import Blockquote from "~/components/flexible/Blockquote";
import TeamSlider from "~/components/flexible/TeamSlider";
import Ctas from "~/components/flexible/Ctas";
import PriceMatchForm from "~/components/flexible/PriceMatchForm";
import RenovationForm from "~/components/flexible/RenovationForm";
import InspectionForm from "~/components/flexible/InspectionForm";

export const FlexibleLayout = (props: { layoutName: String }) => {
  const { layoutName } = props;

  switch (layoutName) {
    case "TwoColumns":
      return <TwoColumns {...(props as any)} />;
    case "Masthead":
      return <Masthead {...(props as any)} />;
    case "Features":
      return <Features {...(props as any)} />;
    case "Process":
      return <Process {...(props as any)} />;
    case "Tabs":
      return <Tabs {...(props as any)} />;
    case "Testimonial":
      return <Testimonial {...(props as any)} />;
    case "Intro":
      return <Intro {...(props as any)} />;
    case "Faqs":
      return <Faqs {...(props as any)} />;
    case "Cta":
      return <Cta {...(props as any)} />;
    case "Steps":
      return <Steps {...(props as any)} />;
    case "FullWidthImage":
      return <FullWidthImage {...(props as any)} />;
    case "Team":
      return <Team {...(props as any)} />;
    case "Comparison":
      return <Comparison {...(props as any)} />;
    case "TabsAlt":
      return <TabsAlt {...(props as any)} />;
    case "Usps":
      return <Usps {...(props as any)} />;
    case "ContentWithImage":
      return <ContentWithImage {...(props as any)} />;
    case "Careers":
      return <Careers {...(props as any)} />;
    case "TwoColumnsAlt":
      return <TwoColumnsAlt {...(props as any)} />;
    case "Contact":
      return <Contact {...(props as any)} />;
    case "ProgressSlides":
      return <ProgressSlides {...(props as any)} />;
    case "ServicesCta":
      return <ServicesCta {...(props as any)} />;
    case "Trustpilot":
      return <Trustpilot {...(props as any)} />;
    case "ComparisonTable":
      return <ComparisonTable {...(props as any)} />;
    case "Values":
      return <Values {...(props as any)} />;
    case "Text":
      return <Text {...(props as any)} />;
    case "Image":
      return <Image {...(props as any)} />;
    case "Video":
      return <Video {...(props as any)} />;
    case "Blockquote":
      return <Blockquote {...(props as any)} />;
    case "TeamSlider":
      return <TeamSlider {...(props as any)} />;
    case "Ctas":
      return <Ctas {...(props as any)} />;
    case "PriceMatchForm":
      return <PriceMatchForm {...(props as any)} />;
    case "RenovationForm":
      return <RenovationForm {...(props as any)} />;
    case "InspectionForm":
      return <InspectionForm {...(props as any)} />;
    default:
      console.log(`Flexible layout mapping not found: ${layoutName}`);
      return null;

  }
};
