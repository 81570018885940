import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Trustpilot } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';

export default function Trustpilot({
  section,
  heading,
}: WpPage_FlexibleContent_FlexibleContent_Trustpilot) {
  return (
    <Section {...section}>
      <div className="container">
        <div className="space-y-20">
          <div className={`max-w-[720px]  mx-auto text-center space-y-8`}>
            <h3 className={`text-h3`} dangerouslySetInnerHTML={{ __html: heading }} />
            {/* <div className="bg-beige inline-block px-4 py-6 pb-0">
              <div className="trustpilot-widget" data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5ddc1603e77eed000124e2c3" data-style-height="150px" data-style-width="100%" data-theme="light">
                <a href="https://www.trustpilot.com/review/punchlistusa.com" target="_blank" rel="noopener">Trustpilot</a>
              </div>
            </div> */}
          </div>
          <div className="trustpilot-widget" data-locale="en-US" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5ddc1603e77eed000124e2c3" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="2,3,4,5" data-review-languages="en">
            <a href="https://www.trustpilot.com/review/punchlistusa.com" target="_blank" rel="noopener">Trustpilot</a>
          </div>
        </div>
      </div>
    </Section>
  )
}
