import React, { useState } from 'react';
import { Section } from '~/components/elements/Section';
import RenovationFormInner from '~/components/elements/RenovationFormInner';
import { Confirmation } from './Contact';

export default function RenovationForm({
  section,
  heading,
  content,
  confirmation,
}: WpPage_FlexibleContent_FlexibleContent_RenovationForm) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className="relative">
      <Section {...section}>
        <div className="container space-y-16">
          {!isSubmitted && (
            <div className="space-y-6 max-w-[680px] mx-auto text-center">
              <h1
                className={`text-h1-large text-span-blue text-center`}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              {content && <p className="p-large">{content}</p>}
            </div>
          )}
          {!isSubmitted ? <RenovationFormInner setIsSubmitted={setIsSubmitted} /> : <Confirmation {...confirmation} />}
        </div>
      </Section>
    </div>
  );
}
