import React, { useState } from 'react';
import Image from '~/components/elements/Image';
import { WpPage_FlexibleContent_FlexibleContent_Contact } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import ContactForm from '~/components/elements/ContactForm';
import { Button, ButtonType, ButtonSize } from '~/components/elements/buttons/Button';
import { ButtonColor } from '~/components/elements/buttons/Button';
import Socials from '~/components/elements/Socials';

export default function Contact({
  section,
  formHeading,
  location,
  confirmation,
  contactNumber,
}: WpPage_FlexibleContent_FlexibleContent_Contact) {
  const [isSubmitted, setIsSubmitted] = useState();
  return (
    <div className="relative">
      <Section {...section}>
        <div className="container space-y-16 md:space-y-32">
          <div className="space-y-8">
            {!isSubmitted && (
              <div className="flex flex-col justify-center mb-12 md:mb-16">
                <h1 className={`text-h3 text-center`} dangerouslySetInnerHTML={{ __html: formHeading }} />
                <a href={`tel:${contactNumber?.number}`} className="mx-auto mt-8 button button--blue">
                  {contactNumber?.label}
                </a>
              </div>
            )}
            {!isSubmitted ? <ContactForm setIsSubmitted={setIsSubmitted} /> : <Confirmation {...confirmation} />}
          </div>
          <Location {...location} />
        </div>
      </Section>
    </div>
  );
}

export const Location = ({ address, heading, image, link, socials }) => {
  return (
    <div className="px-8 py-16 space-y-8 bg-lime lg:px-32 lg:space-y-0 lg:flex ">
      <div className="md:w-[19%]">
        <div className="w-24">
          <Image image={image} />
        </div>
      </div>
      <div className="md:w-[53%] space-y-6">
        <h3 className={`text-h3 !text-black location-heading`} dangerouslySetInnerHTML={{ __html: heading }} />
        <div className="text-black" dangerouslySetInnerHTML={{ __html: address }} />
      </div>
      <div className="md:w-[27%] ml-auto">
        <Button link={link.link} type={link.type} color={ButtonColor.Black} />
        <div className="-ml-3 text-black">
          <Socials menu={socials} className="!justify-start" />
        </div>
      </div>
    </div>
  );
};

export const Confirmation = ({ heading, content, link }) => {
  return (
    <div className="w-full mx-auto text-center">
      <h1 className={`text-h2 text-center text-span-blue`} dangerouslySetInnerHTML={{ __html: heading }} />
      <div className="mt-4 font-medium md:mt-8" dangerouslySetInnerHTML={{ __html: content }} />
      <div className="mt-6 md:mt-10">
        <Button link={link?.link} type={link.type} color={ButtonColor.Blue} />
      </div>
    </div>
  );
};
