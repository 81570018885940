import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Process } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Process({
  section,
  contentgroup,
  blocks,
}: WpPage_FlexibleContent_FlexibleContent_Process) {
  return (
    <Section {...section}>
      <div className="container">
        <div className="lg:grid grid-cols-12 space-y-12 lg:space-y-0">
          <div className="col-span-6 relative">
            <div className="sticky top-[16vh] lg:pr-12 space-y-7">
              <FadeInWhenVisible>
                <h2 className="text-h2 process-heading !leading-[1.08]" dangerouslySetInnerHTML={{ __html: contentgroup.heading }} />
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <div className="p-xlarge process-content max-w-[505px]" dangerouslySetInnerHTML={{ __html: contentgroup.content }} />
              </FadeInWhenVisible>
            </div>
          </div>
          <div className="col-span-5 col-start-8" >
            <div className="relative space-y-[20vh] ">
              {blocks.map((block, i) => (
                <Block {...block} key={`processblock${i}`} i={i} length={blocks.length} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export const Block = ({ backgroundColour, content, heading, image, i, length }) => {

  let bg = "";
  switch (backgroundColour) {
    case "teal":
      bg = "bg-teal";
      break;
    case "orange":
      bg = "bg-orange";
      break;
    case "light-blue":
      bg = "bg-blue-light";
      break;
    case "aero":
      bg = "bg-aero";
      break;
  }

  return (
    <div className={`sticky top-[120px] lg:top-[16vh]`}>
      <div className={`${bg} py-12 px-8 md:px-20 text-center rounded-[7px] process-card relative min-h-[450px] md:min-h-[500px] flex flex-col justify-center`}>
        <div className="absolute top-5 right-3 w-12 h-12 bg-navy text-white rounded-full flex items-center justify-center">
          <span className="block mt-1">0{i + 1}</span>
        </div>
        <div className="space-y-10">
          <div className="w-[188px] h-[130px] mx-auto">
            <Image image={image} className="h-full w-auto" objectFit="contain" objectPosition="center" />
          </div>
        <div className="space-y-5">
            <h5 className="text-h5 text-h5--moranga" dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="text-[17px] leading-6">{content}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
