import React from "react";
import { Section } from "~/components/elements/Section";
import {
  WpPost_Article_FlexibleContent_Blockquote,
} from "src/_generated/types";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Blockquote({
  quote,
  section,
}: WpPost_Article_FlexibleContent_Blockquote) {
  return (
    <FadeInWhenVisible>
      <Section {...section}>
        <div className="border-l-4 border-l-blue pl-6 md:pl-10">
          <h4 className="text-h4 !font-normal" dangerouslySetInnerHTML={{ __html: quote }} />
        </div>
      </Section>
    </FadeInWhenVisible>
  );
}
