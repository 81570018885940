import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_Ctas } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Button, ButtonColor } from "~/components/elements/buttons/Button"
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Ctas({
  section,
  ctas
}: WpPage_FlexibleContent_FlexibleContent_Ctas) {

  if(!ctas) return null;

  return (
    <Section {...section}>
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-6">
          {ctas.map((cta, i) => (
            <Cta {...cta} i={i} key={`ctasCta${i}`} />
          ))}
        </div>
      </div>
    </Section>
  )
}


export const Cta = ({heading, content, link, backgroundColour, i}) => {

  let bg = "";
  switch (backgroundColour) {
    case "lime":
      bg = "bg-lime";
      break;
    case "blue":
      bg = "bg-blue-light";
      break;
  }

  return (
    <FadeInWhenVisible className={`${bg} px-10 py-20 text-center space-y-6 md:space-y-12 flex flex-col items-center justify-center`} transition={{delay: i * .2}}>
      <div className="space-y-2.5">
        <h3 className="text-h3 max-w-[400px] mx-auto" dangerouslySetInnerHTML={{__html: heading}} />
        {content && <div className="p-xlarge">{content}</div>}        
      </div>
      {link.link && <Button link={link.link} type={link.type} color={ButtonColor.Black} />}
    </FadeInWhenVisible>
  )
}
