import React from 'react'
import { Icon, IconType } from "~/components/elements/Icon";

export default function Tick() {
  return (
    <div className="w-[26px] h-[26px] flex-shrink-0 rounded-full bg-blue text-white flex justify-center items-center group-hover:bg-navy transition-colors duration-300 ease-in-out">
      <div className="w-[14px] fw-icon">
        <Icon type={IconType.Tick} />
      </div>
    </div>
  )
}
