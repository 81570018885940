import React from 'react'
import { WpPage_FlexibleContent_FlexibleContent_ContentWithImage } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'


export default function ContentWithImage({
  section,
  heading,
  image,
  content,
  layout,
}: WpPage_FlexibleContent_FlexibleContent_ContentWithImage) {
  return (
    <Section {...section}>
      <div className="container">
        <div className={`text-center ${layout === 'center' ? 'text-center space-y-7' : 'flex flex-col space-y-4 md:space-y-6'}`}>
          <FadeInWhenVisible>
            <h3 className={`text-h3 order-1`} dangerouslySetInnerHTML={{__html: heading}} />
          </FadeInWhenVisible>
          <FadeInWhenVisible className={` max-w-[1082px] mx-auto leading-normal order-3 ${layout === 'center' ? 'max-w-[645px]  p-xlarge' : 'p-xxlarge'}`} dangerouslySetInnerHTML={{__html: content}} />
          <FadeInWhenVisible className={`mx-auto w-full ${layout === 'center' ? 'max-w-[898px]  order-4 pt-16' : 'max-w-[778px] order-2 py-12'} `}>
            <Image image={image} />
          </FadeInWhenVisible>
        </div>
      </div>
    </Section>
  )
}
