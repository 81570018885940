import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { AnimatePresence, motion } from "framer-motion";
import Tabs from "./Tabs";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function TabsAlt({
  section,
  tab1,
  tab2,
}: WpPage_FlexibleContent_FlexibleContent_TabsAlt) {

  const [active, setActive] = useState(0);

  return (
    <div className="p-5">
      <div className="rounded-[5px] overflow-hidden">
        <Section {...section}>
          <div className="container">
            <div className="mx-auto space-y-12 lg:space-y-24">
              <ProgressTabs tab1={tab1} tab2={tab2} active={active} setActive={setActive} />
              <Slides tab1={tab1} tab2={tab2} active={active} />
            </div>
          </div>
        </Section>
      </div>
    </div>
  )
}

export const ProgressTabs = ({ tab1, tab2, active, setActive }) => {
  return (
    <FadeInWhenVisible className="max-w-[588px] flex  mx-auto">
      <button className={`text-center tab-alt-progress-text border-b-2 border-navy-dark border-opacity-20 flex-1 cursor-pointer appearance-none py-2 lg:py-6 relative outline-none ${active === 1 && 'hover:text-blue transition-colors duration-300 ease-in-out'}`} onClick={() => setActive(0)}>
        {tab1.label}
        <div className={`absolute bottom-[-2px] right-0 bg-navy-dark h-0.5 transition-all duration-500 ${active === 0 ? 'w-full' : 'w-0'} `}></div>
      </button>
      <button className={`text-center font-moranga font-light tab-alt-progress-text border-b-2 border-navy-dark border-opacity-20 flex-1 cursor-pointer appearance-none py-2 lg:py-6 relative outline-none ${active === 0 && 'hover:text-blue transition-colors duration-300 ease-in-out'}`} onClick={() => setActive(1)}>
        {tab2.label}
        <div className={`absolute bottom-[-2px] left-0 bg-navy-dark h-0.5 transition-all duration-500 ${active === 1 ? 'w-full' : 'w-0'} `}></div>
      </button>
    </FadeInWhenVisible>
  )
}

export const Slides = ({ tab1, tab2, active }) => {

  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => swiper?.slideTo(index);

  useLayoutEffect(() => {
    slideTo(active);
  }, [active]);


  return (
    <div className={`${active === 0 ? "max-w-[996px] mx-auto" : ""} lg:grid grid-cols-12 space-y-12 lg:space-y-0 items-center`}>
      <div className="col-span-5">
        <AnimatePresence>
          {active === 0 && (
            <motion.div
              transition={{ duration: 0.5 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
            >
              <TabContent tab={tab1} active={active} i={0} />
            </motion.div>
          )}
          {active === 1 && (
            <motion.div
              transition={{ duration: 0.5 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
            >
              <TabContent tab={tab2} active={active} i={1} />
            </motion.div>
          )}
        </AnimatePresence>

      </div>
      <div className="col-span-6 col-start-7">
        <AnimatePresence>
          {active === 0 && (
            <motion.div
              transition={{ duration: 0.5 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
            >
              <TabSlide1 tab={tab1} active={active} />
            </motion.div>
          )}
          {active === 1 && (
            <motion.div
              transition={{ duration: 0.5 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
            >
              <TabSlide2 tab={tab2} active={active} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export const TabSlide1 = ({ tab, active }) => {
  return (
    <AnimatePresence>
      {active === 0 && (
        <motion.div
          transition={{ duration: 0.5 }}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 10, opacity: 0 }}
        >
          <div className="max-w-[412px] mx-auto">
            <Image image={tab.image} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export const TabSlide2 = ({ tab, active }) => {
  return (
    <AnimatePresence>
      {active === 1 && (
        <div className="space-y-16">
          {tab.features.map((feature, i) => (
            <motion.div
              className="md:flex space-y-6 md:space-y-0 md:space-x-12 items-center"
              transition={{ duration: 0.5 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}>
              <div className="w-28 flex-shrink-0">
                <Image image={feature.image} objectFit="contain" objectPosition="center" className="h-full w-full" />
              </div>
              <div className="space-y-3">
                <h6 className="text-h6" dangerouslySetInnerHTML={{ __html: feature.heading }} />
                <div className="text-[17px]">{feature.content}</div>
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </AnimatePresence>
  )
}

export const TabContent = ({ tab, active, i }) => {
  return (
    <AnimatePresence>
      {active === i && (
        <motion.div
          transition={{ duration: 0.5 }}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 10, opacity: 0 }}
        >
          <div className="lg:max-w-[443px] space-y-8 ml-auto">
            <h3 className="text-h3 !leading-none" dangerouslySetInnerHTML={{ __html: tab.heading }} />
            <p className="p-xlarge leading-[1.6]">{tab.content}</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

