import React from 'react'
import { WpPost_Article_FlexibleContent_Text } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Text({
  section,
  content,
}: WpPost_Article_FlexibleContent_Text) {
  return (
    <FadeInWhenVisible>
      <Section {...section}>
        <div dangerouslySetInnerHTML={{__html: content}} className={`prose article-text ${section.background !== 'white' && 'py-12 px-8 md:px-16'}`} />
      </Section>
    </FadeInWhenVisible>
  )
}
