import React from "react";
import { Section } from "~/components/elements/Section";
import {
  WpPost_Article_FlexibleContent_Image,
} from "src/_generated/types";
import ImageInner from "~/components/elements/Image";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function Image({
  image,
  caption,
  section,
}: WpPost_Article_FlexibleContent_Image) {

  return (
    <FadeInWhenVisible>
      <Section {...section} >
        <div className="px-0 lg:w-[140%] lg:ml-[-40%] flex flex-col">
          <ImageInner image={image} />
          {caption && <div className="pt-6 p-large">{caption}</div>}
        </div>
      </Section>
    </FadeInWhenVisible>
  );
}
